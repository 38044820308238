<template>
  <div class="doubt">
    <div class="doubt-warp">
      <div class="title">FREQUENTLY ASKED QUESTIONS(FAQ)</div>
      <div class="title1">How can I open a case?</div>
      <div class="con">
        <span>Login with your Steam account.
          Click on your profile image on top.Enter your Trade Offer URL and click on "Save". You can find it here: https://steamcommunity.com/privacy#trade_offer_access_url
          Click on "ADD FUNDS" button on top.Choose a case which you want to buy, click on the case and then choose "Open Case".
        </span>
      </div>
      <div class="title1" style="margin-top: 42px;">Where can I find my Steam Trade Offer URL?</div>
      <div class="con">
        <span>Open your steam trade offers page:
          https://steamcommunity.com/id/me/tradeoffers/privacy#trade_offer_access_url</span>
      </div>
      <div class="title1" style="margin-top: 42px;">I got an error when I am trying to pick up the item: "Sending item can take more time. Please wait for 12 minutes". What does it mean?</div>
      <div class="con">
        <span>
          Do not worry. This is not an error. It warns you that sending a trade offer for that item may take a little longer. Due to the recent limitations of Steam trades, we have to use a difficult system of sending items, because Steam often blocks active accounts. Our bot can deliver skin from 3 to 15 minutes.
        </span>
      </div>
      <div class="title1" style="margin-top: 42px;">Do I need to make a deposit before I can withdraw items?</div>
      <div class="con">
        <span>Yes. You must deposit to your balance at least once to enable withdrawals. Minimum deposit amount is $4.</span>
      </div>
      <div class="title1" style="margin-top: 42px;">I didn't received any trade offers after I pressed on Accept button. What should I do?</div>
      <div class="con">
        <span>
          There are a few possible reasons why we may be unable to send you a trade offer: You are not able to use Steam Trades (logged in from the new device, recently reset the password or e-mail, changed the Steam Guard security settings, VAC). You can check it by sending a trade offer to a friend. Your Steam Inventory settings (CHECK) is set to "Hidden". You need switch it to "Public", in order to receive trade offers. Check your trade offer url on your bbultra-box profile and in the Steam settings - they should match. Bot have a connection issues. This happens quite often, the problem is on the side of a Steam service. Bot can also be locked, in this case contact bbultra-box support and describe the problem in detail, or send email to us.
        </span>
      </div>
      <div class="title1" style="margin-top: 42px;">How can I receive my CS:GO item?</div>
      <div class="con">
        <span>
          Go to your bbultra-box.com profile page and click on "ACCEPT" button for the item you want withdraw to.You will receive your items via trade offer, which you can find here: https://steamcommunity.com/id/me/tradeoffers
        </span>
      </div>
    </div>
    <div class="footer-content">
      <img class="placeholder-img" src="../assets/img/footer/footer-bg.png" alt="" srcset=""/>
      <div>
        <br>
        <br>
        <br>
        <br>
      </div>
      <ul class="list">
        <li v-for="(item, index) in routerList" @click="fun(item.url)" :key="`${item.url}-${index}`">
          <span class="link-name">{{ item.name }}</span>
          <span v-if="index + 1 != routerList.length">|</span>
        </li>
      </ul>
      <div class="copyright">
        <div class="ba-right">
          Powered By Ultra Business Tech S.A.C. @2024
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      routerList: [
        { name: "About Us", url: "Abouts" },
        { name: "Privacy Policy", url: "Privacy" },
        { name: "FAQ", url: "Clause" },
        { name: "Terms Of Service", url: "Agreement" },
      ],
      urlList: [],
      info: {
        player_num: 0,
        online_num: 0,
        openbox_num: 0,
      },
    };
  },
  created() {
    this.$axios
        .post("/index/Login/logininfo", this.$qs.stringify({}))
        .then((res) => {
          if (res.data.status) {
            this.info = res.data.data;
          }
        });
  },
  methods: {
    fun(url) {
      this.$router.push({
        path: `/${url}`,
      });
    },
  },
};
</script>

<style lang="less" scoped>
.doubt {
  overflow: hidden;
  overflow-y: auto;
  width: 100%;
  height: 100%;
  background-color: #1a1c24;
  padding-top: 42px;

  .doubt-warp {
    width: 1200px;
    margin: 0 auto;
    padding: 32px;
  }

  .title {
    padding: 20px 0 64px 0;
    font-size: 30px;
    color: #c3c3e2;
  }

  .title1 {
    font-size: 20px;
    color: #c3c3e2;
    padding: 16px 0;
  }

  .con {
    font-size: 18px;
    color: #848492;

    span {
      margin-top: 10px;
      display: inline-block;
    }
  }
}
.copyright {
  max-width: 800px;
  margin: 20px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .ba-right {
    color: #878787;
    font-size: 12px;
    cursor: pointer;
  }
  .top-right {
    margin-top: 20px;
  }
}
.footer-content {
  background: transparent;
  color: #fff;
  .placeholder-img {
    width: 100%;
  }
  .list {
    max-width: 800px;
    margin: 20px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    img {
      cursor: pointer;
      margin-bottom: 10px;
    }
    li {
      margin-right: 10px;
      font-size: 12px;

      .link-name {
        margin-right: 10px;
        cursor: pointer;
      }
    }
  }
  .platform-info {
    max-width: 800px;
    margin: 40px auto;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
    .item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;
      height: 100px;
      font-size: 14px;
      & > span:nth-child(1) {
        font-weight: bold;
        font-size: 16px;
      }
    }
    .center {
      position: relative;
      &::before {
        display: block;
        content: "";
        width: 1px;
        height: 100px;
        position: absolute;
        left: 0;
        top: 0;
        background-image: -webkit-gradient(
            linear,
            left top,
            left bottom,
            from(hsla(0, 0%, 100%, 0.1)),
            color-stop(#ccc),
            to(hsla(0, 0%, 100%, 0.1))
        );
        background-image: -webkit-linear-gradient(
            hsla(0, 0%, 100%, 0.1),
            #ccc,
            hsla(0, 0%, 100%, 0.1)
        );
        background-image: -o-linear-gradient(
            hsla(0, 0%, 100%, 0.1),
            #ccc,
            hsla(0, 0%, 100%, 0.1)
        );
        background-image: linear-gradient(
            hsla(0, 0%, 100%, 0.1),
            #ccc,
            hsla(0, 0%, 100%, 0.1)
        );
      }
      &::after {
        display: block;
        content: "";
        width: 1px;
        height: 100px;
        position: absolute;
        right: 0;
        top: 0;
        background-image: -webkit-gradient(
            linear,
            left top,
            left bottom,
            from(hsla(0, 0%, 100%, 0.1)),
            color-stop(#ccc),
            to(hsla(0, 0%, 100%, 0.1))
        );
        background-image: -webkit-linear-gradient(
            hsla(0, 0%, 100%, 0.1),
            #ccc,
            hsla(0, 0%, 100%, 0.1)
        );
        background-image: -o-linear-gradient(
            hsla(0, 0%, 100%, 0.1),
            #ccc,
            hsla(0, 0%, 100%, 0.1)
        );
        background-image: linear-gradient(
            hsla(0, 0%, 100%, 0.1),
            #ccc,
            hsla(0, 0%, 100%, 0.1)
        );
      }
    }
  }
}
</style>