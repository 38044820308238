<template>
  <div class="rule">
    <div class="rule-warp">
      <div class="roomleft-num">
        <span @click="goLucky">Blind Box Battle</span>
        <span> ></span>
        <span>Rule</span>
      </div>

      <div class="rule-text">
        <div class="text-title">About Fighting</div>
        <p>
          1
          To create a battle, you can choose the number of people to fight (1V1, 3 person battle, and 4 person battle), and a maximum of options for a melee can be selected
          6 blind boxes. Once a room is created, it cannot be cancelled.
        </p>
        <p>
          2
          The participation fee for the battle is the total price of the blind box opened in this battle. After paying the fee, it is considered as joining the room and exiting the room midway without refunding the blind box or participation fee.
        </p>
        <p>
          3
          When multiple winners in a battle share the rewards equally, non personal items will be exchanged for coins and distributed to the winner's account balance.
        </p>
        <p>
          4
          The outcome of each battle is influenced by various factors such as creation time, user ID, user location, and start time, and is a completely random outcome. Any blind box reward can verify fairness.
        </p>
        //
      </div>

      <!-- <div class="rule-input">
        <div class="input-title">请输入对战场次编号</div>
       <div class="input">
          <input type="text" />
          <span>验证对战</span>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    goLucky() {
      this.$router.push({
        path: `/Lucky`,
      });
    },
  },
};
</script>

<style lang="less" scoped>
.rule {
  overflow: hidden;
  overflow-y: auto;
  width: 100%;
  height: 100%;
  background-color: #1a1c24;

  .rule-warp {
    padding: 16px;
  }
  .rule-text {
    margin-top: 20px;
    .text-title {
      padding: 10px 0;
      color: #c3c3e2;
      font-size: 16px;
    }
    p {
      font-size: 14px;
      color: #848492;
      padding: 8px 0;
    }
  }
  .rule-input {
    display: table;
    margin-top: 30px;
    padding: 16px;
    background-color: #2b2c37;
    border-radius: 5px;
    .input-title {
      font-size: 16px;
      color: #c3c3e2;
    }
    .input {
      padding: 16px 0;
      input {
        height: 40px;
        line-height: 40px;
        padding: 0 10px;
        border-radius: 5px;
        width: 400px;
        color: #c3c3e2;
        font-size: 12px;
        background-color: #24252f;
        outline: none;
        box-sizing: border-box;
        border: none;
      }
      span {
        display: inline-table;
        height: 40px;
        margin-left: 20px;
        line-height: 40px;
        padding: 0 20px;
        background-color: #e9b10e;
        border-radius: 5px;
        font-weight: 600;
        color: #1a1c24;
        font-size: 15px;
      }
      span:hover {
        cursor: pointer;
        background-color: #f5c432;
      }
    }
  }

  .roomleft-num {
    display: flex;
    align-items: center;
    color: #848492;
    font-size: 14px;
    span {
      margin-right: 5px;
    }
    span {
      font-size: 14px;
      font-weight: 200;
    }
    span:hover {
      cursor: pointer;
      color: #e9b10e;
      text-decoration: underline;
    }
    span {
      font-weight: 200;
      color: #c3c3e2;
      font-size: 14px;
    }
  }
}
</style>
