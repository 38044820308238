<template>
  <div class="doubt">
    <div class="doubt-warp">
<!--      <div class="title">隐私条款</div>-->
      <div class="title">Privacy Policy</div>
      <div class="con">
        ULTRA BUSINESS TECH S.A.C.reserves the right to modify this Privacy Policy periodically, and any modifications will become effective upon the availability of the updated Privacy Policy on this Application. We kindly request that you consult this Privacy Policy whenever you provide your personal information.
        <br>
        <h3>Types of Data collected</h3><br>
        This Application collects various types of Personal Data, either directly or through third parties. The collected data includes Cookies and Usage Data. Additional Personal Data may be mentioned in other sections of this privacy policy or explained in dedicated contextually relevant texts in relation to the Data collection process. The Personal Data may be freely provided by the User, or collected automatically when using this Application. This Application and its third-party service providers may utilize Cookies or other tracking tools to identify Users and remember their preferences, solely for the purpose of delivering the required service, unless otherwise specified. Please note that certain Personal Data must be provided to ensure the provision of services by this Application. Failure to provide such data may result in the inability to access or utilize the Application's services. Users are responsible for obtaining the necessary consent from third parties for any Personal Data obtained, published, or shared through this Application. By doing so, Users confirm that they have obtained the consent of the relevant third party to provide their Data to the Owner.
        <h3>Mode and place of processing the Data: Methods of processing</h3><br>
        The Data Controller ensures the responsible processing of Users' Data and implements necessary security measures to prevent unauthorized access, disclosure, modification, or destruction of the Data. Data processing is conducted using computers and/or IT-enabled tools, following specific organizational procedures and modes directly related to the stated purposes. Besides the Data Controller, certain individuals in charge of site operations (such as administration, sales, marketing, legal, and system administration) or external parties (such as third-party technical service providers, mail carriers, hosting providers, IT companies, and communications agencies) may have access to the Data as Data Processors, if required. An updated list of these parties can be requested from the Data Controller at any time.
        <h3>Place</h3><br>
        The Data is processed at the Data Controller's operating offices and in any other places where the parties involved with the processing are located. For further information, please contact the Data Controller.
        <h3>Retention time</h3><br>
        The Data is retained for the duration required to fulfill the User's requested service or as stated by the purposes outlined in this document. The User has the option to request the suspension or deletion of their data from the Data Controller. Under no circumstances will the Data be retained for a period exceeding 5 years after the User's most recent activity on the site.
        <h3>How Do We Protect Your Information?</h3><br>
        Our utmost priority is safeguarding your privacy, and we take extensive measures to protect and secure the processing and transfer of your personal data. However, it's important to acknowledge that due to the inherent nature of the Internet, there may be instances where data is transmitted over networks without comprehensive security measures and could potentially be accessed by unauthorized individuals.
        <h3>How Do We Use Your Information?</h3><br>
        We will utilize your personal information for legitimate purposes, including responding to your inquiries, sending relevant information, fulfilling contractual obligations, conducting research, and providing marketing updates about our products, services, and related opportunities. These uses are subject to your explicit consent. If you make a purchase from us, we may send you additional information related to your purchase via post, push notifications, or email. We would like to send you marketing information through push notifications and email. If you prefer not to receive any of the aforementioned information or be contacted in accordance with the described methods, please click the "Unsubscribe" button in our emails or contact bbultra-box at the provided address. If you submit your opinions or feedback via our contact form, we may internally share them for training and management purposes. We periodically review the accuracy of the personal information we collect and assess whether it can be deleted. We may combine the information you provide with other data in an aggregated form (where identification is not possible) and utilize it for administrative purposes. Push notifications This Application may send push notifications to the User.
        <h3>The rights of Users</h3><br>
        Users may exercise certain rights regarding their Data processed by the Owner.In particular, Users have the right to do the following:
        <p>Withdraw their consent at any time. Users have the right to withdraw consent where they have previously given their consent to the processing of their Personal Data.</p>
        <p>Object to processing of their Data. Users have the right to object to the processing of their Data if the processing is carried out on a legal basis other than consent. Further details are provided in the dedicated section below.</p>
          <p>Access their Data. Users have the right to learn if Data is being processed by the Owner, obtain disclosure regarding certain aspects of the processing and obtain a copy of the Data undergoing processing.</p>
            <p>Verify and seek rectification. Users have the right to verify the accuracy of their Data and ask for it to be updated or corrected.</p>
              <p>Restrict the processing of their Data. Users have the right, under certain circumstances, to restrict the processing of their Data. In this case, the Owner will not process their Data for any purpose other than storing it.</p>
                <p>Have their Personal Data deleted or otherwise removed. Users have the right, under certain circumstances, to obtain the erasure of their Data from the Owner.</p>
                  <p>Receive their Data and have it transferred to another controller. Users have the right to receive their Data in a structured, commonly used and machine readable format and, if technically feasible, to have it transmitted to another controller without any hindrance. This provision is applicable provided that the Data is processed by automated means and that the processing is based on the User's consent, on a contract which the User is part of or on pre-contractual obligations thereof.</p>
                    <p>Lodge a complaint. Users have the right to bring a claim before their competent data protection authority.</p>
        <h3>Definitions and legal references</h3><br>
        <h3>Personal Data (or Data)</h3><br>
        Personal information refers to any data that, either directly or indirectly, or in combination with other information, enables the identification or identifiability of a natural person. This may encompass details such as name, address, passport or ID data, personal identification number, and any other information that, when considered as a whole, could lead to the identification of an individual.
        <h3>Usage Data</h3><br>
        This Site automatically collects certain information, including but not limited to: the IP addresses or domain names of the computers used by Users accessing the Site, the URI addresses (Uniform Resource Identifier), the time of the request, the method used to submit the request to the server, the size of the file received in response, the numerical code indicating the status of the server's response (such as a successful outcome or an error), the country of origin, the browser and operating system features utilized by the User, specific time details per visit (e.g., duration on each page within the Site), details of the user's navigation path within the Site including the sequence of pages visited, and additional parameters related to the device's operating system and/or the User's IT environment.
        <h3>User</h3><br>
        The individual using this Site who, unless otherwise specified, coincides with the Data Subject.
        <h3>Data Subject</h3><br>
        The natural person to whom the Personal Data refers.
        <h3>Data Processor (or Data Supervisor)</h3><br>
        The natural or legal person, public authority, agency or other body which processes Personal Data on behalf of the Controller, as described in this privacy policy.
        <h3>Data Controller (or Owner)</h3><br>
        The Data Controller refers to the individual or entity, whether a natural or legal person, public authority, agency, or other body, that is responsible for determining the purposes and methods of processing Personal Data, as well as implementing security measures related to the operation and usage of this Site. Unless stated otherwise, the Owner of this Site assumes the role of the Data Controller.
        <h3>This Application</h3><br>
        The means by which the Personal Data of the User is collected and processed.
        <h3>Service</h3><br>
        The service provided by this Site as described in the relative terms (if available) and on this Site.
        <h3>Cookies</h3><br>
        Small piece of data stored in the User's device.
      </div>
      <br>
    </div>
    <div class="footer-content">
      <img class="placeholder-img" src="../assets/img/footer/footer-bg.png" alt="" srcset=""/>
      <div>
        <br>
        <br>
        <br>
        <br>
      </div>
      <ul class="list">
        <li v-for="(item, index) in routerList" @click="fun(item.url)" :key="`${item.url}-${index}`">
          <span class="link-name">{{ item.name }}</span>
          <span v-if="index + 1 != routerList.length">|</span>
        </li>
      </ul>
      <div class="copyright">
        <div class="ba-right">
          Powered By Ultra Business Tech S.A.C. @2024
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      routerList: [
        { name: "About Us", url: "Abouts" },
        { name: "Privacy Policy", url: "Privacy" },
        { name: "FAQ", url: "Clause" },
        { name: "Terms Of Service", url: "Agreement" },
      ],
      urlList: [],
      info: {
        player_num: 0,
        online_num: 0,
        openbox_num: 0,
      },
    };
  },
  created() {
    this.$axios
        .post("/index/Login/logininfo", this.$qs.stringify({}))
        .then((res) => {
          if (res.data.status) {
            this.info = res.data.data;
          }
        });
  },
  methods: {
    fun(url) {
      this.$router.push({
        path: `/${url}`,
      });
    },
  },
};
</script>

<style lang="less" scoped>
.doubt {
  overflow: hidden;
  overflow-y: auto;
  width: 100%;
  height: 100%;
  background-color: #1a1c24;
  padding-top: 42px;
  .doubt-warp {
    width: 1200px;
    margin: 0 auto;
  }
  .title {
    padding: 20px 0 64px 0;
    font-size: 30px;
    color: #c3c3e2;
  }
  .con {
    font-size: 18px;
    color: #848492;
    padding-bottom: 88px;
    h3 {
      color: #ddd;
      font-weight: normal;
      margin-top: 42px;
    }
  }
}
p { text-indent: 1em; /* 首行缩进2em */ }
.copyright {
  max-width: 800px;
  margin: 20px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .ba-right {
    color: #878787;
    font-size: 12px;
    cursor: pointer;
  }
  .top-right {
    margin-top: 20px;
  }
}
.footer-content {
  background: transparent;
  color: #fff;
  .placeholder-img {
    width: 100%;
  }
  .list {
    max-width: 800px;
    margin: 20px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    img {
      cursor: pointer;
      margin-bottom: 10px;
    }
    li {
      margin-right: 10px;
      font-size: 12px;

      .link-name {
        margin-right: 10px;
        cursor: pointer;
      }
    }
  }
  .platform-info {
    max-width: 800px;
    margin: 40px auto;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
    .item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;
      height: 100px;
      font-size: 14px;
      & > span:nth-child(1) {
        font-weight: bold;
        font-size: 16px;
      }
    }
    .center {
      position: relative;
      &::before {
        display: block;
        content: "";
        width: 1px;
        height: 100px;
        position: absolute;
        left: 0;
        top: 0;
        background-image: -webkit-gradient(
            linear,
            left top,
            left bottom,
            from(hsla(0, 0%, 100%, 0.1)),
            color-stop(#ccc),
            to(hsla(0, 0%, 100%, 0.1))
        );
        background-image: -webkit-linear-gradient(
            hsla(0, 0%, 100%, 0.1),
            #ccc,
            hsla(0, 0%, 100%, 0.1)
        );
        background-image: -o-linear-gradient(
            hsla(0, 0%, 100%, 0.1),
            #ccc,
            hsla(0, 0%, 100%, 0.1)
        );
        background-image: linear-gradient(
            hsla(0, 0%, 100%, 0.1),
            #ccc,
            hsla(0, 0%, 100%, 0.1)
        );
      }
      &::after {
        display: block;
        content: "";
        width: 1px;
        height: 100px;
        position: absolute;
        right: 0;
        top: 0;
        background-image: -webkit-gradient(
            linear,
            left top,
            left bottom,
            from(hsla(0, 0%, 100%, 0.1)),
            color-stop(#ccc),
            to(hsla(0, 0%, 100%, 0.1))
        );
        background-image: -webkit-linear-gradient(
            hsla(0, 0%, 100%, 0.1),
            #ccc,
            hsla(0, 0%, 100%, 0.1)
        );
        background-image: -o-linear-gradient(
            hsla(0, 0%, 100%, 0.1),
            #ccc,
            hsla(0, 0%, 100%, 0.1)
        );
        background-image: linear-gradient(
            hsla(0, 0%, 100%, 0.1),
            #ccc,
            hsla(0, 0%, 100%, 0.1)
        );
      }
    }
  }
}
</style>