export function battle() {
  //const wsuri = 'wss://localhost:8000/ws/'
  const wsuri = 'wss://csgo.bbultra-box.com/ws/'
  // const wsuri = 'wss://mg.local.com/ws/'
  // const wsuri = 'wss://www.yjcsgo.top/ws/'
  // const wsuri = "wss://cz-chunxinhuanbao.com/ws/";
  // const wsuri = "ws://192.168.101.12:7654/";
  // const wsuri = "ws://47.120.33.201:8760/";
  return wsuri
}
export function openBox() {
 // const wsuri = 'wss://localhost:8080/wss/'
  const wsuri = 'wss://csgo.bbultra-box.com/wss/'
  // const wsuri = 'wss://mg.local.com/wss/'
  // const wsuri = 'wss://www.yjcsgo.top/wss/'
  // const wsuri = "wss://cz-chunxinhuanbao.com/wss/";
  // const wsuri = "ws://192.168.101.12:7655/";
  // const wsuri = "ws://47.120.33.201:8760/";
  return wsuri
}
