<template>
  <div class="roll">
    <navheader
      :showNav="showNav"
      :yidongmenu="yidongmenu"
      :openHongbao="openHongbao"
    ></navheader>
    <div class="roll-warp">
      <div class="roll-hint">
<!--        选择你心仪的商品（您的可用金币数量为<span>-->
        Choose your favorite product（Your available coins are<span>
          {{ $store.state.money }} </span
        >)
      </div>
      <!-- <div class="buy-btn-phone">
        <span @click="buyCarPhone">我的购物车</span>
      </div> -->
      <!-- <div class="roll-pirce">
        <div class="left" @click="listSort">
          {{ priceSort ? "价格从高到低" : "价格从低到高" }}
        </div>
        <div class="right">
          <div class="input">
            <el-input v-model="input1" type="number">
              <img src="../assets/img/masonry.png" slot="prefix" />
            </el-input>
          </div>
          <span>-</span>
          <div class="input">
            <el-input v-model="input2" type="number">
              <img src="../assets/img/masonry.png" slot="prefix" />
            </el-input>
          </div>
          <div class="screen" @click="screen">筛选</div>
        </div>
      </div> -->

      <div class="oran-sel">
        <div class="sel-top">
          <ul>
            <li
              v-for="(item, index) in selList"
              :key="index"
              @click="selOran(item.status, item.id)"
            >
              <div
                :class="
                  item.id == classId
                    ? 'seltop-warp seltop-warp1'
                    : 'seltop-warp'
                "
              >
                <img :src="item.img" />
                <span>{{ item.name }}</span>
              </div>
            </li>
          </ul>
        </div>
        <div class="class-box">
          <div class="class-list" v-if="classList.length">
            <ul>
              <li v-for="(item, index) in classList" :key="index">
                <span
                  :class="item.id == subClassId ? 'active' : 'span2'"
                  @click="setSubClassId(item)"
                  >{{ item.name }}</span
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="roll-pirce">
        <div class="right">
          <div class="sort-block" @click="listSort">
            <div style="color: #333; font-size: 14px; font-weight: bold">
              price
            </div>
            <div class="sort-icon">
              <i
                class="iconfont icon-shangjiantou icon"
                :class="{ 'active-icon': priceSort }"
              ></i>
              <i
                class="iconfont icon-xiajiantou icon"
                :class="{ 'active-icon': !priceSort }"
              ></i>
            </div>
          </div>

          <el-input
            placeholder="search by name"
            v-model="searchKey"
            style="width: calc(100% - 86px); max-width: 350px"
            class="input1"
          >
            <i
              slot="suffix"
              class="el-input__icon el-icon-search input1-i"
              @click="getList(page)"
            ></i>
          </el-input>
        </div>
      </div>
      <div class="roll-list">
        <ul>
          <li
            v-for="(item, index) in list"
            :key="index"
            @click="buyState(item.id)"
          >
            <div class="list-warp">
              <div :class="item.state ? 'list-bor' : ''"></div>
              <span class="ico" v-if="item.exteriorName">{{
                item.exteriorName
              }}</span>
              <span v-if="item.state" class="buy-state">
                <img src="../assets/img/gou.png" />
              </span>
              <div class="list-img">
                <img :src="item.imageUrl" />
              </div>
              <div class="bot">
                <div class="list-name" :title="item.itemName">
                  {{ item.itemName }}
                </div>
                <div class="list-pirce">
                  <div class="pirce-left">
                    <img src="../assets/img/masonry.png" />
                    <span>{{ item.price }}</span>
                  </div>
<!--                  <div class="pirce-right">库存 {{ item.stock }}</div>-->
                  <div class="pirce-right">inventory {{ item.stock }}</div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="clear"></div>
      <div class="roll-page">
        <el-pagination
          background
          layout="prev, pager, next"
          :total="totalSize"
          :page-size="pageSize"
          @current-change="currentChange"
        >
        </el-pagination>
      </div>
    </div>
    <div class="clear"></div>
    <div class="myhomebot-mag"></div>
    <myhomebot></myhomebot>
    <div class="go-mall-car" @click="showSure = true" v-if="total_num">
      <img src="../assets/img/masonry.png" style="height: 18px" />
      <span style="margin: 0 10px 0 5px">{{ total_price }}</span>
      <span>Buy</span>
    </div>
    <!-- 购买 确认弹窗  -->
    <div class="buy-sure" v-if="showSure">
      <div class="sure">
        <div class="title">Confirm purchase?</div>
        <div class="btns">
          <div class="btn" @click="showSure = false">Cancel</div>
          <div class="btn sure-btn" @click="goBuy">Ok</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import myhomebot from "@/components/my_homebot.vue";
import myinform from "@/components/my_inform.vue";
import Utils from "./../assets/js/util.js";
import navheader from "@/components/navheader.vue";
export default {
  components: { myhomebot, myinform, navheader },
  props: ["yidongmenu", "showNav", "openHongbao"],
  data() {
    return {
      loading: false,
      buyCarState: false,
      input1: "",
      input2: "",
      totalSize: 0,
      page: 1,
      pageSize: 24,
      list: [],
      buyCart: [],
      total_num: 0,
      total_price: 0,
      priceSort: true,

      searchKey: "",
      // 品质
      pzList: [],
      // 外观
      wgList: [],
      // 品质选中
      rarity: "Unlimited",
      // 外观选中
      exterior: "Unlimited",
      // 主分类id
      classId: "",
      // 小分类id
      subClassId: "all",
      // 推荐
      recommend: 1,
      // 二级分类
      classList: [],
      // 一级分类
      selList: [],

      showSure: false,
    };
  },
  watch: {
    searchKey: {
      handler(value) {
        if (value.length) return;
        this.getList(this.page);
      },
    },

    // 监听一级分类选中获取二级分类
    classId: {
      handler(value) {
        if (!value) return;
        this.classList = [
          {
            /*name: "全部",*/
            name: "all",
            id: "all",
          },
        ];
        this.subClassId = "all";

        this.$axios
          .post(
            "/index/Lucky/subclassList",
            this.$qs.stringify({ type_id: value })
          )
          .then((res) => {
            if (res.data.status) {
              this.classList.push(...res.data.data.list);
              this.subClassId = this.classList[0].id;
            }
          });
      },
    },
  },
  mounted() {
    this.getListClass();
    this.getList(this.page);
    this.getTagAndPz();
  },
  methods: {
    setSubClassId(item) {
      this.subClassId = item.id;
      this.page = 1;
      this.getList();
    },
    selOran(status, id) {
      this.recommend = status == 2 ? 1 : 2;
      this.classId = id;
      this.getList();
    },
    getListClass() {
      this.$axios.post("/index/Lucky/luckyTypeList").then((res) => {
        let data = res.data;
        if (data.status == 1) {
          this.selList = data.data;
          this.classId = this.selList[0].id;
        }
      });
    },
    getTagAndPz() {
      this.$axios
        .post("/index/Store/exterior", this.$qs.stringify({}))
        .then((res) => {
          const { exterior, rarity } = res.data.data;
          this.pzList = ["Unlimited"].concat(rarity.map((item) => item.rarityName));
          this.wgList = ["Unlimited"].concat(
            exterior.map((item) => item.exteriorName)
          );
        });
    },
    //分页
    currentChange(val) {
      this.getList(val);
    },
    //商城列表
    getList(val) {
      let param = {
        page: val,
        pageSize: this.pageSize,
      };
      if (this.priceSort) {
        param["order"] = "asc";
      }
      if (this.searchKey) {
        param["searchKey"] = this.searchKey;
      }
      if (this.classId && this.classId != "all") {
        param["type_id"] = this.classId;
      }
      if (this.subClassId && this.subClassId != "all") {
        param["subclass_id"] = this.subClassId;
      }
      if (this.rarity && this.rarity != "Unlimited") {
        param["rarity"] = this.rarity;
      }
      if (this.exterior && this.exterior != "Unlimited") {
        param["exterior"] = this.exterior;
      }
      if (this.classId == "1") {
        param["recommend"] = 1;
        delete param.type_id;
      }

      this.$axios
        .post("index/Store/skinList", this.$qs.stringify(param))
        .then((res) => {
          let data = res.data;
          if (data.status == 1) {
            this.totalSize = data.data.total;
            this.list = data.data.list;
            for (let i = 0; i < this.list.length; i++) {
              this.list[i].state = false;
            }
            for (let i = 0; i < this.buyCart.length; i++) {
              for (let j = 0; j < this.list.length; j++) {
                if (this.buyCart[i].id == this.list[j].id) {
                  this.list[j].state = true;
                }
              }
            }
          }
        });
    },
    //手机端
    buyCarPhone() {
      this.buyCarState = !this.buyCarState;
    },
    offBuyCar() {
      this.buyCarState = false;
    },
    //取消全部
    offAll() {
      this.buyCart = [];
      for (let i = 0; i < this.list.length; i++) {
        this.list[i].state = false;
      }
      this.buyNum();
    },
    //加一
    addBuy(id) {
      for (let i = 0; i < this.buyCart.length; i++) {
        if (this.buyCart[i].id == id) {
          this.buyCart[i].num = this.buyCart[i].num + 1;
        }
      }
      this.buyNum();
      this.$forceUpdate();
    },
    //减一
    subBuy(id) {
      for (let i = 0; i < this.buyCart.length; i++) {
        if (this.buyCart[i].id == id) {
          this.buyCart[i].num = this.buyCart[i].num - 1;
        }
        if (this.buyCart[i].num == 0) {
          this.buyCart.splice(i, 1);
          for (let j = 0; j < this.list.length; j++) {
            if (this.list[j].id == id) {
              this.list[j].state = false;
            }
          }
        }
      }
      this.buyNum();
      this.$forceUpdate();
    },
    buyState(id) {
      for (let i = 0; i < this.list.length; i++) {
        if (id == this.list[i].id) {
          this.list[i].state = !this.list[i].state;
          if (this.list[i].state == true) {
            this.buyCart.push(this.list[i]);
            for (let j = 0; j < this.buyCart.length; j++) {
              if (id == this.buyCart[j].id) {
                this.buyCart[j].num = 1;
              }
            }
          } else {
            for (let j = 0; j < this.buyCart.length; j++) {
              if (id == this.buyCart[j].id) {
                this.buyCart.splice(j, 1);
              }
            }
          }
        }
      }
      this.buyNum();
      this.$forceUpdate();
    },
    //购物车数量和价格
    buyNum() {
      this.total_num = 0;
      this.total_price = 0;
      for (let i = 0; i < this.buyCart.length; i++) {
        this.total_num += this.buyCart[i].num;
        this.total_price += Number(this.buyCart[i].price) * this.buyCart[i].num;
      }
      this.total_price = this.total_price.toFixed(2);
    },
    //立即购买
    goBuy() {
      this.loading = true;
      let param = {
        skin_info: this.buyCart,
      };
      this.$axios
        .post("index/Store/buynew", this.$qs.stringify(param))
        .then((res) => {
          let data = res.data;
          if (data.status == 1) {
            this.loading = false;
            this.buyCart = [];
            for (let i = 0; i < this.list.length; i++) {
              this.list[i].state = false;
            }
            this.total_num = 0;
            this.total_price = 0;
            this.$store.commit("getMoney", data.data.total_amount);
            Utils.$emit("masonry", data.data.total_amount);
            this.$message({
              message: data.msg,
              type: "success",
            });
            this.showSure = false;
          } else {
            this.loading = false;

            let msg = res.data.msg;
            if (msg.indexOf("Insufficient balance") != -1) {
              this.$message({ message: "Insufficient balance, please recharge first", type: "warning" });
              return;
            }
            if (msg.indexOf("Insufficient inventory of goods") != -1) {
              this.$message({ message: "Insufficient inventory of goods", type: "warning" });
              return;
            }
            if (msg.indexOf("Please contact the administrator") != -1) {
              this.showSure = false;
              this.$message({
                message: "Event users do not support purchasing accessories. Please contact the administrator",
                type: "warning",
              });
              return;
            }
            this.showSure = false;
            this.$message({ message: "Please log in first", type: "warning" });
            this.$store.commit("getLogin", true);
          }
        });
    },
    //数据排序
    listSort() {
      this.priceSort = !this.priceSort;
      this.getList();
    },
    //筛选
    screen() {
      let param = {
        page: this.page,
        pageSize: this.pageSize,
        minPrice: this.input1,
        maxPrice: this.input2,
      };
      this.$axios
        .post("index/Store/skinList", this.$qs.stringify(param))
        .then((res) => {
          let data = res.data;
          if (data.status == 1) {
            this.totalSize = data.data.total;
            this.list = data.data.list;
            this.priceSort = true;
            for (let i = 0; i < this.list.length; i++) {
              this.list[i].state = false;
            }
            for (let i = 0; i < this.buyCart.length; i++) {
              for (let j = 0; j < this.list.length; j++) {
                if (this.buyCart[i].id == this.list[j].id) {
                  this.list[j].state = true;
                }
              }
            }
          }
        });
    },
  },
};
</script>

<style lang="less" scoped>
::v-deep {
  .el-input__inner {
    background-color: #2b2c37;
    border: 1px solid #e9b10e !important;
    color: #c3c3e2;
  }
}
.active {
  color: #000 !important;
}
.oran-sel {
  // background: url("../assets/95/linebg.png") no-repeat;
  background-size: 100% 100%;
  border-top: 1px solid #e9b10e;
  margin: 20px auto 0 auto;
  background-color: #2b2c37;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 750px) {
    .sel-top {
      ul {
        display: grid !important;
        grid-template-columns: repeat(auto-fit, minmax(80px, 1fr)) !important;
        li {
          width: 100% !important;
        }
      }
    }
  }

  .sel-top {
    border-bottom: 1px solid #e9b10e;
    background-color: #1a1c24;
    ul {
      display: flex;
      flex-wrap: wrap;
      li {
        width: 150px;
        .seltop-warp {
          margin: 0 1px;
          display: flex;
          flex-direction: column;
          align-items: center;

          img {
            width: 60%;
            height: auto;
            max-height: 78px;
          }
          span {
            padding-bottom: 10px;
            font-size: 12px;
            color: #878787;
          }
        }
        .seltop-warp1 {
          background-color: #2b2c37;
          span {
            color: #e9b10e;
          }
        }
      }
      li:hover {
        cursor: pointer;
      }
    }
  }
  .class-box {
    background-color: #2b2c37;
    padding: 0 20px 0px 20px;
    .class-list {
      margin-top: 15px;
      ul {
        display: flex;
        li {
          margin-right: 10px;
          span {
            border: 1px solid #ffc400;
            border-radius: 5px;
            padding: 5px 16px;
            font-size: 12px;
            color: #fff;
          }
          .span2 {
            border: 1px solid #ffc400;
            color: #fff;
            font-size: 12px;
          }
          span:hover {
            cursor: pointer;
            background-color: #ffc400;
            color: #000;
          }
        }
      }
    }
  }
  .sel-bot {
    display: flex;
    flex-wrap: wrap;
    & > div {
      margin: 30px 0 10px 0px;
      &:last-child {
        display: flex;
        justify-content: flex-start;
      }
    }

    .selbot-left {
      flex: 1;
      min-width: 200px;
      min-height: 60px;
      .pz-container,
      .wg-container {
        display: flex;
        align-items: center;
        font-size: 14px;
        padding: 5px 0;
        .name {
          color: #fff;
          width: 45px;
        }
        .wg-content {
          color: #848492;
          flex: 1;
          white-space: nowrap;
          overflow: auto;
          span {
            padding: 0 10px;
            cursor: pointer;
            &:hover {
              color: #e9b10e;
            }
          }
          .active {
            color: #e9b10e;
          }
        }
      }
    }

    .selbot-right {
      max-width: 400px;
      // margin-left: 10px;
      display: flex;
      align-items: center;
      .span {
        margin: 0 8px;
        color: #848492;
      }
      .pirec-btn {
        margin: 0 10px;
        background-color: #333542;
        border-radius: 5px;
        color: #848492;
        font-size: 15px;
        font-weight: 600;
        padding: 10px 26px;
      }
      .pirec-btn:hover {
        cursor: pointer;
        background-color: #3a3f50;
      }
      .input {
        width: 120px;
        img {
          width: auto;
          height: 18px;
        }
      }
      .input /deep/ .el-input__prefix,
      .input /deep/ .el-input__suffix {
        top: 11px;
      }
      .input1 {
        width: 200px;
      }
      .input1-i:hover {
        cursor: pointer;
      }
    }
    .selbot-right /deep/ .el-input__inner {
      background-color: #2b2c37;
      border: 1px solid #e9b10e;
      color: #c3c3e2;
    }
  }
}

.buy-sure {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 999;
  color: #fff;
  .sure {
    position: absolute;
    width: 80%;
    height: 20%;
    max-width: 500px;
    max-height: 500px;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    padding: 20px;
    border-radius: 10px;
    box-sizing: border-box;
    background-color: #2b2c37;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .title {
      font-size: 20px;
      text-align: center;
    }
    .btns {
      display: flex;
      align-items: center;
      justify-content: center;
      .btn {
        padding: 8px 30px;
        font-size: 14px;
        border: 1px solid #e9b10e;
        cursor: pointer;
      }
      .sure-btn {
        background-color: #e9b10e;
        margin-left: 20px;
        color: #000;
      }
    }
  }
}
.class-list {
  margin: 10px 0;
  height: 50px;
  background-color: #2b2c37;
  ul {
    display: flex;
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    height: 100%;
    li {
      margin-right: 10px;
      line-height: 50px;
      span {
        background-color: #e9b10e;
        border-radius: 5px;
        padding: 8px 16px;
        font-size: 14px;
      }
      .span2 {
        border: 1px solid #848492;
        background-color: #2b2c37;
        color: #848492;
        font-size: 14px;
      }
      span:hover {
        cursor: pointer;
        background-color: #e9b10e;
        color: #000;
      }
    }
  }
}

.go-mall-car {
  position: fixed;
  bottom: 20px;
  left: 50%;
  background-color: #e9b10e;
  color: #000000;
  padding: 8px 0;
  font-weight: bold;
  font-family: ninefont !important;
  width: calc(100% - 80px);
  max-width: 600px;
  transform: translate(-50%, 0);
  border-radius: 50px;
  box-shadow: 0px 16px 48px 16px rgba(0, 0, 0, 0.08),
    0px 12px 32px rgba(0, 0, 0, 0.12), 0px 8px 16px -8px rgba(0, 0, 0, 0.16);
  font-size: 18px;
  z-index: 2;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.roll {
  width: 100;
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
  background-color: #1a1c24;

  .myhomebot-mag {
    margin-top: 120px;
  }

  .roll-warp {
    height: 100%;
    padding: 16px 16px 16px 16px;

    .roll-hint {
      font-size: 16px;
      color: #848493;

      span {
        color: #e9b10e;
      }
    }

    .roll-pirce {
      margin-bottom: 30px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: #2b2c37;
      padding: 0 20px 20px 20px;
      .sort-block {
        background-color: #e9b10e;
        border-radius: 5px;
        height: 40px;
        padding: 0 10px;
        display: flex;
        align-items: center;
        color: #333;
        display: flex;
        font-size: 15px;
        align-items: center;
        margin-right: 10px;
        cursor: pointer;
        user-select: none;
        .sort-icon {
          display: flex;
          flex-direction: column;
          margin-left: 10px;
          .icon {
            color: #333;
            font-size: 10px !important;
          }
          .active-icon {
            color: #fff !important;
          }
        }
      }

      .left {
        flex: 1;
        min-width: 200px;
        min-height: 60px;
        .pz-container,
        .wg-container {
          display: flex;
          align-items: center;
          font-size: 14px;
          padding: 5px 0;
          width: 100%;
          .name {
            color: #fff;
            width: 45px;
          }
          .wg-content {
            color: #848492;
            // flex: 1;
            white-space: nowrap;
            overflow: auto;
            span {
              padding: 0 10px;
              cursor: pointer;
              &:hover {
                color: #e9b10e;
              }
            }
            .active {
              color: #e9b10e;
            }
          }
        }
      }

      .right {
        max-width: 400px;
        display: flex;
        align-items: center;
        // margin-left: 10px;

        span {
          margin-left: 5px;
          margin-right: 5px;
          color: #e9b10e;
        }
        .screen {
          height: 40px;
          line-height: 40px;
          margin-left: 20px;
          background-color: #e9b10e;
          padding: 0 30px;
          font-weight: 600;
          color: #1a1c24;
          border-radius: 5px;
        }
        .screen:hover {
          cursor: pointer;
        }
        .input {
          width: 100px;
          img {
            width: auto;
            height: 15px;
          }
        }
        .input /deep/ .el-input__prefix,
        .el-input__suffix {
          top: 12.5px;
        }
        .input /deep/ .el-input__inner {
          background-color: #000;
          color: white;
          border: 1px solid #893e8a;
        }
      }
    }

    .roll-list {
      margin-top: 20px;
      ul {
        margin: 0 -4px;
        li {
          width: 16.66%;
          float: left;
          .list-warp {
            margin: 4px;
            background-color: #2b2c37;
            border-radius: 5px;
            position: relative;
            overflow: hidden;
            .list-bor {
              position: absolute;
              top: 0;
              z-index: 666;
              width: 100%;
              height: 100%;
              background-color: rgba(233, 177, 14, 0.1);
            }

            .ico {
              position: absolute;
              top: 0;
              left: 0;
              font-size: 12px;
              color: #050505;
              background-color: rgb(230, 189, 7);
              padding: 2px 4px;
            }
            .buy-state {
              position: absolute;
              top: 0;
              right: 0;
              img {
                width: 15px;
                height: 15px;
              }
            }
            .list-img {
              background-image: url("../assets/95/item_bg.png");
              background-size: 100% 100%;
              padding: 0 20px;
              overflow: hidden;
              // height: 130px;
              img {
                width: 100%;
                height: 100%;
              }
            }
            .bot {
              background-color: #2b2c37;
              .list-name {
                // text-align: center;
                font-size: 16px;
                color: #c3c3e2;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
              .list-pirce {
                padding: 10px 8px;
                display: flex;
                justify-content: space-between;
                .pirce-left {
                  display: flex;
                  align-items: center;

                  img {
                    width: auto;
                    height: 15px;
                    margin-right: 5px;
                  }
                  span {
                    color: #e9b10e;
                    font-size: 16px;
                  }
                }
                .pirce-right {
                  color: #848492;
                  font-size: 14px;
                }
              }
            }
          }
        }
        li:hover {
          cursor: pointer;
        }
        li:hover .bot {
          background-color: #444659;
        }
      }
    }

    .roll-page {
      margin: 10px 0 0 -10px;
    }
    .roll-page
      /deep/
      .el-pagination.is-background
      .el-pager
      li:not(.disabled).active {
      background-color: #e9b10e;
      color: #1a1c24;
    }
    .roll-page /deep/ .el-pagination.is-background .btn-next,
    /deep/ .el-pagination.is-background .btn-prev,
    /deep/ .el-pagination.is-background .el-pager li {
      background-color: #333542;
      color: #848492;
    }
  }

  .roll-right {
    position: fixed;
    width: 250px;
    height: 100%;
    top: 60px;
    right: 0px;
    z-index: 888;
    background-color: #30313f;
    text-align: center;
    border-left: 1px solid #30313f;

    .off {
      padding: 16px;
      background-color: #1a1c24;

      span {
        background-color: #30313f;
        border-radius: 5px;
        padding: 8px 16px;
        color: #848492;
        font-size: 14px;
        font-weight: 600;
      }
      span:hover {
        cursor: pointer;
      }

      .buy-btn-phone1 {
        display: none;
        margin-left: 10px;
        padding: 8px 16px;
        background-color: #e9b10e;
        border-radius: 5px;
        color: #1a1c24;
        font-size: 15px;
        font-weight: 600;
      }
    }
    .shopping-box {
      height: calc(~"100vh - 300px");
      padding: 16px 16px 16px 8px;

      .num {
        text-align: center;
        padding: 0 0 16px 0;
        color: #e9b10e;
      }
      .shopping-cart {
        height: 100%;
        overflow: hidden;
        overflow-y: auto;

        ul li {
          margin-top: 10px;
          margin-right: 10px;
          padding: 8px;
          background-color: #2b2c37;
          .cart-warp {
            .cart-top {
              display: flex;
              .cart-img {
                min-width: 70px;
                max-width: 70px;
                min-height: 55px;
                max-height: 55px;
                background-image: url("../assets/img/box-skins-blue.jpg");
                background-size: 100% 100%;
                text-align: center;
                img {
                  width: 100%;
                  height: auto;
                }
              }
              .cart-name {
                margin-left: 10px;
                h5 {
                  font-size: 14px;
                  color: #c3c3e2;
                }
                h6 {
                  display: flex;
                  align-items: center;
                  img {
                    width: auto;
                    height: 15px;
                  }
                  span {
                    margin-left: 5px;
                    color: #e9b10e;
                    font-size: 16px;
                  }
                }
              }
            }
            .cart-bot {
              display: flex;
              align-items: center;
              span {
                color: #c3c3e2;
              }
              .num {
                background-color: #444659;
                padding: 3px 12px;
                font-size: 15px;
              }
              .sub,
              .add {
                font-size: 24px;
                padding: 0 12px;
              }
              .sub:hover,
              .add:hover {
                cursor: pointer;
              }
            }
          }
        }

        img {
          width: 50px;
          height: 50px;
        }
      }
      /*滚动条样式*/
      .shopping-cart::-webkit-scrollbar {
        width: 4px;
        /*height: 4px;*/
      }
      .shopping-cart::-webkit-scrollbar-thumb {
        border-radius: 10px;
        box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        background: rgba(0, 0, 0, 0.2);
      }
      .shopping-cart::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        border-radius: 0;
        background: rgba(0, 0, 0, 0.1);
      }
    }
    .buy {
      background-color: #2b2c37;
      padding: 16px;
      text-align: center;
      font-size: 14px;
      color: #c3c3e2;
      span {
        margin-left: 10px;
        color: #e9b10e;
      }
    }
    .buy-btn {
      margin-top: 20px;
      text-align: center;
      .el-btn {
        color: #1a1c24;
        font-size: 15px;
        font-weight: 600;
      }
      span {
        color: #1a1c24;
        background-color: #e9b10e;
        padding: 8px 80px;
        border-radius: 5px;
        font-size: 15px;
        font-weight: 600;
      }
      span:hover {
        cursor: pointer;
        background-color: #f5c432;
      }
    }
  }
  .roll-right1 {
    display: block;
    z-index: 88;
  }
  .buy-btn-phone {
    display: none;
    margin-top: 20px;
    span {
      padding: 8px 22px;
      background-color: #e9b10e;
      border-radius: 5px;
      color: #1a1c24;
      font-size: 15px;
      font-weight: 600;
    }
  }
}
</style>
