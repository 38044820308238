<template>
  <div class="hist">
    <div class="hist-warp">
      <div class="roomleft-num">
        <span @click="goLucky">Blind Box Battle</span>
        <span>></span>
        <span>Battle Record</span>
      </div>

      <div class="hist-list">
        <el-tabs type="border-card" v-model="activeName" @tab-click="getTab">
          <el-tab-pane label="All records" name="one">
            <BattleBox :list="tableData" />
            <div class="roll-page">
              <el-pagination
                background
                layout="prev, pager, next"
                :total="totalSize"
                :page-size="pageSize"
                @current-change="currentChange"
              >
              </el-pagination>
            </div>
          </el-tab-pane>

          <el-tab-pane label="My Battle Record" name="two">
            <BattleBox :list="tableData1" />

            <!-- <div class="bot">
              <el-table :data="tableData1" style="width: 100%">
                <el-table-column prop="create_time" label="时间">
                </el-table-column>
                <el-table-column prop="room_num" label="编号">
                </el-table-column>
                <el-table-column label="玩家">
                  <template slot-scope="scope">
                    <el-image
                      v-for="(item, index) in scope.row.player_info"
                      :key="index"
                      style="
                        width: 30px;
                        height: 30px;
                        border-radius: 50%;
                        margin-right: 5px;
                        margin-top: 15px;
                      "
                      :src="item.img"
                      :preview-src-list="[item.img]"
                    ></el-image>
                  </template>
                </el-table-column>
                <el-table-column label="盲盒信息">
                  <template slot-scope="scope">
                    <el-image
                      v-for="(item, index) in scope.row.boxInfo"
                      :key="index"
                      style="width: 40px; height: 40px"
                      :src="item.img_main"
                      :preview-src-list="[item.img_main]"
                    ></el-image>
                  </template>
                </el-table-column>
                <el-table-column prop="statusName" label="状态">
                </el-table-column>
                <el-table-column label="对战结果">
                  <template slot-scope="scope">
                    <span v-html="reResult(scope)"></span>
                  </template>
                </el-table-column>
                <el-table-column label="精彩回顾">
                  <template slot-scope="scope">
                    <a
                      @click="goLuckyRoom(scope.row.battle_id)"
                      style="cursor: pointer"
                      >查看</a
                    >
                  </template>
                </el-table-column>
              </el-table>
            </div> -->
            <div class="roll-page">
              <el-pagination
                background
                layout="prev, pager, next"
                :total="totalSize1"
                :page-size="pageSize1"
                @current-change="currentChange1"
              >
              </el-pagination>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import { parse } from "qs";
import { BattleBox } from "@/views/common";
export default {
  components: { BattleBox },
  data() {
    return {
      activeName: "one",
      totalSize: 0,
      page: 1,
      pageSize: 24,
      tableData: [],
      totalSize1: 0,
      page1: 1,
      pageSize1: 10,
      tableData1: [],
    };
  },
  mounted() {
    this.getHist(this.page);
  },
  methods: {
    getTab(tab) {
      if (tab.name == "one") {
        this.getHist(this.page);
      } else {
        this.getMyHist(this.page1);
      }
    },
    getHist(page) {
      let param = {
        page: page,
        pageSize: this.pageSize,
      };
      this.$axios
        .post("/index/Battle/battleList", this.$qs.stringify(param))
        .then((res) => {
          // console.log(res.data);
          var data = res.data;
          if (data.status == "1" && data.data != null) {
            this.totalSize = data.data.total;
            let result = data.data.battleList;
            for (let i = 0; i < result.length; i++) {
              result[i].boxInfo = result[i].boxInfo;
              result[i].price = 0;
              var info = result[i].boxInfo;
              for (let j = 0; j < info.length; j++) {
                result[i].price += Number(info[j].price);
              }
              result[i].price = result[i].price.toFixed(2);
            }
            //几个人参与
            for (let i = 0; i < result.length; i++) {
              let play = JSON.parse(JSON.stringify(result[i].player_info));
              let mode = result[i].mode;
              for (let j = 0; j < mode - result[i].player_info.length; j++) {
                play.push({
                  img: require("../assets/img/jiapeople.png"),
                  id: play.length,
                  class: "pk-false",
                  border: "1px dashed #e9b10e",
                });
              }
              for (let x = 0; x < play.length; x++) {
                if (play[x] && play[x].id) {
                  play[x].class = "pk-true";
                  play[x].img = play[x].img;
                }
              }
              result[i].player_info = play;

              if (mode == "3") {
                result[i].play1 = [];
                result[i].play2 = [];
                result[i].play1.push(play[0]);
                result[i].play2.push(play[1]);
                result[i].play2.push(play[2]);
              }
              if (mode == "4") {
                result[i].play1 = [];
                result[i].play2 = [];
                result[i].play1.push(play[0]);
                result[i].play1.push(play[1]);
                result[i].play2.push(play[2]);
                result[i].play2.push(play[3]);
              }
            }
            this.tableData = result;
          }
          // for (let i = 0; i < this.tableData.length; i++) {
          //   if (this.tableData[i].status == 1) {
          //     this.tableData[i].statusName = "等待中";
          //   } else if (this.tableData[i].status == 2) {
          //     this.tableData[i].statusName = "进行中";
          //   } else {
          //     this.tableData[i].statusName = "已结束";
          //   }
          // }
        });
    },
    getMyHist(page) {
      let param = {
        page: page,
        pageSize: this.pageSize1,
      };
      this.$axios
        .post("index/Battle/battleHistory", this.$qs.stringify(param))
        .then((res) => {
          //  console.log(res.data);
          var data = res.data;
          if (data.status == "1" && data.data != null) {
            this.totalSize1 = data.data.total;
            let result = data.data.list;
            for (let i = 0; i < result.length; i++) {
              result[i].boxInfo = JSON.parse(result[i].boxInfo);
              result[i].price = 0;
              var info = result[i].boxInfo;
              for (let j = 0; j < info.length; j++) {
                result[i].price += Number(info[j].price);
              }
              result[i].price = result[i].price.toFixed(2);
            }
            //几个人参与
            for (let i = 0; i < result.length; i++) {
              let play = JSON.parse(JSON.stringify(result[i].player_info));
              let mode = result[i].mode;
              for (let j = 0; j < mode - result[i].player_info.length; j++) {
                play.push({
                  img: require("../assets/img/jiapeople.png"),
                  class: "pk-false",
                  id: play.length,
                  border: "1px dashed #e9b10e",
                });
              }
              for (let x = 0; x < play.length; x++) {
                if (play[x] && play[x].id) {
                  play[x].class = "pk-true";
                  play[x].img = play[x].img;
                }
              }
              result[i].player_info = play;

              if (mode == "3") {
                result[i].play1 = [];
                result[i].play2 = [];
                result[i].play1.push(play[0]);
                result[i].play2.push(play[1]);
                result[i].play2.push(play[2]);
              }
              if (mode == "4") {
                result[i].play1 = [];
                result[i].play2 = [];
                result[i].play1.push(play[0]);
                result[i].play1.push(play[1]);
                result[i].play2.push(play[2]);
                result[i].play2.push(play[3]);
              }
            }
            this.tableData1 = result;
            // for (let i = 0; i < this.tableData1.length; i++) {
            //   this.tableData1[i].boxInfo = JSON.parse(
            //     this.tableData1[i].boxInfo
            //   );
            //   if (this.tableData1[i].status == 1) {
            //     this.tableData1[i].statusName = "等待中";
            //   } else if (this.tableData1[i].status == 2) {
            //     this.tableData1[i].statusName = "进行中";
            //   } else {
            //     this.tableData1[i].statusName = "已结束";
            //   }
            // }
          }
        });
    },
    //分页 所有记录
    currentChange(val) {
      this.getHist(val);
    },
    //分页 我的记录
    currentChange1(val) {
      this.getMyHist(val);
    },
    goLucky() {
      this.$router.push({
        path: `/Lucky`,
      });
    },
    goLuckyRoom(id) {
      console.log(`/LuckyRoom?id=${id}`);
      this.$router.push({
        path: `/LuckyRoom?id=${id}`,
      });
    },
    // 返回对接结果
    reResult(scope) {
      let winner = scope.row.winner.split(",");
      let me = scope.row.player_id + "";
      console.log(winner, me);
      console.log(winner.indexOf(me));
      if (winner.indexOf(me) != -1) {
        return '<span style="color: #7c7cec;font-size: 16px;">胜利</span>';
      } else {
        return '<span style="color: #e64d4d;font-size: 16px;">失败</span>';
      }
    },
  },
};
</script>

<style lang="less" scoped>
::v-deep {
  .el-tabs__content {
    padding: 0 !important;
  }
  .el-tabs__item.is-active {
    background-color: #e9b10e !important;
    color: #000000 !important;
    font-weight: bold;
  }
  .el-tabs__header {
    margin-bottom: 20px !important;
  }
  .el-tabs__item:not(.is-disabled, .is-active):hover {
    color: #e9b10e !important;
  }
}

.hist {
  overflow: hidden;
  overflow-y: auto;
  width: 100%;
  height: 100%;
  background-color: #1a1c23;

  .hist-warp {
    padding: 16px;
  }

  //tabs
  .hist-list {
    margin-top: 20px;
  }
  .hist-list /deep/ .el-tabs--border-card {
    //overflow: hidden;
    background-color: #191b23;
    border: none;
    // border-radius: 5px;
  }
  .hist-list /deep/ .el-tabs--border-card > .el-tabs__header {
    border: none;
    background-color: #1a1c23;
  }
  .hist-list
    /deep/
    .el-tabs--border-card
    > .el-tabs__header
    .el-tabs__item.is-active {
    background-color: #2b2c37;
    border: none;
  }
  .hist-list /deep/ .el-tabs--border-card > .el-tabs__header .el-tabs__item {
    background-color: #24252f;
    border: none;
  }
  .hist-list
    /deep/
    .el-tabs--border-card
    > .el-tabs__header
    .el-tabs__item.is-active {
    color: #e9b10e;
  }

  //页数
  .roll-page {
    padding: 20px 0;
    text-align: center;
  }
  .roll-page
    /deep/
    .el-pagination.is-background
    .el-pager
    li:not(.disabled).active {
    background-color: #e9b10e;
    color: #1a1c24;
  }
  .roll-page /deep/ .el-pagination.is-background .btn-next,
  /deep/ .el-pagination.is-background .btn-prev,
  /deep/ .el-pagination.is-background .el-pager li {
    background-color: #333542;
    color: #848492;
  }
  //表格
  .bot /deep/ .el-table th,
  .bot /deep/ .el-table tr {
    background-color: #2b2c37;
  }
  .bot /deep/ .el-table td,
  .bot /deep/ .el-table th.is-leaf {
    border-bottom: 1px solid #444659;
  }
  .bot /deep/ .el-table::before {
    height: 0;
  }
  .bot /deep/ .el-table--enable-row-hover .el-table__body tr:hover > td {
    background-color: #212e3e !important;
  }
  .bot /deep/ .cell {
    padding: 0;
    height: 60px;
    line-height: 60px;
  }
  .bot /deep/ .el-table__empty-block {
    background-color: #2b2c37;
  }

  .roomleft-num {
    display: flex;
    align-items: center;
    color: #848492;
    font-size: 14px;
    span {
      margin-right: 5px;
    }

    span {
      font-size: 14px;
      font-weight: 200;
    }
    span:hover {
      cursor: pointer;
      color: #e9b10e;
      text-decoration: underline;
    }
    span {
      font-weight: 200;
      color: #c3c3e2;
      font-size: 14px;
    }
  }
}
</style>
