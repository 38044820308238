<template>
  <div class="doubt">
    <div class="doubt-warp">
      <ul>
        <li>
          <div class="name">contact us</div>
          <p class="p">Contact us and we will process your feedback as soon as possible</p>
          <p class="p">Customer service email：{{email}}</p>
          <p class="p">QQ Group：{{qq}}</p>
        </li>
        <li>
          <div class="name">Regular issues</div>

          <div class="name-title">1.Unable to register/log in through Steam</div>
          <p class="title-p">Due to domestic restrictions, users may encounter issues such as inability to connect and access denial when registering/logging in with Steam. Users can solve this problem by installing an online game accelerator to accelerate the Steam community. If the problem still exists, you can try to solve it by replacing the browser.</p>

          <div class="name-title">2.Steam transaction link acquisition</div>
          <div class="title1">2.1 PC end users</div>
          <div class="title2">(1).<span @click="goUrl(url1)">Obtain Steam transaction address</span></div>
          <div class="title2">(2).Find third-party websites to obtain Steam transaction links</div>
<!--          <div class="img"><img src="../assets/img/faq/faq1.png"> </div>-->
          <div class="title1">2.2 Mobile users</div>
          <div class="title2">(1).Download the Steam App, log in, and follow the process "You and Friends - Inventory - Transaction Quotation - Right Menu Button - Who can send me a transaction quotation" to find the transaction link at the bottom.</div>
<!--          <div class="img"><img src="../assets/img/faq/faq2.png"> </div>-->

          <div class="name-title">3.Recharge related issues</div>
          <div class="title3">Q：What is the recharge fee??</div>
          <div class="title4">A：The platform will charge a handling fee of 15% of the total amount of jewelry recharge (still higher than the market price). The transaction fees are used to balance the cost expenses of jewelry price fluctuations, account lock up risks, and payment of transaction fees to third-party platforms during jewelry sales caused by the 7-day trading limit.</div>
          <div class="title3">Q：Why does jewelry recharge display unavailable accessories?</div>
          <div class="title4">A：Jewelry with transaction cooling or restrictions cannot be recharged.</div>
          <div class="title3">Q：Why can my accessories be traded in the Steam market but still show as unavailable during recharge?</div>
          <div class="title4">A：Due to cost control, the website does not support accessories with poor circulation in the market. Please understand.</div>
          <div class="title3">Q：Recharge not received?</div>
          <div class="title4">A：There may be delay in Alipay/WeChat payment system, and the account is still not received after more than 3 minutes. Please contact the customer service on the website in time to deal with it. Customer service QQ：1073161290。</div>

          <div class="name-title">4.Daily blind box activity related issues</div>
          <div class="title3">Q：Why does it prompt me that my Steam game time is less than 10 hours</div>
          <div class="title4">A：Open Steam profile, edit profile, find privacy settings, ensure that all settings are kept public, and ensure that the total game time below is private and not marked as √. If there are still problems after setting, please contact customer service to refresh.</div>
<!--          <div class="img"><img src="../assets/img/faq/faq3.png"></div>-->
          <div class="title3">Q：Why didn't I get a blind box when I invited someone?</div>
          <div class="title4">A：1）The players you invite also need to meet the conditions for obtaining a daily blind box (they need to be bound to a Steam account, set the account to public, and the game duration needs to be greater than 10 hours) in order to be recognized as a valid invitation by the system.</div>
          <div class="title4">2）Each user can receive a maximum of 5 blind boxes per day. If you have already obtained 5 blind boxes today, you will not receive any more blind boxes.</div>

          <div class="name-title">5.Jewelry related issues</div>
          <div class="title3">Q：Why does it prompt me that the accessory cannot be retrieved?</div>
          <div class="title4">A： Multiple attempts to retrieve the jewelry may be due to restricted trading permissions on the account. Please open the link to find out the reason for the non tradable item before attempting to retrieve it again，<span @click="goUrl(url2)">Go to the Steam customer service page to check the reason</span></div>
<!--          <div class="img"> <img src="../assets/img/faq/faq4.png"></div>-->
          <div class="title4">PS：If the problem still persists, you can replace it with an available Steam account and bind the transaction URL to retrieve it, or contact customer service to resolve the issue.</div>
          <div class="title3">Q：Steam not publicly available</div>
          <div class="title4">A：Open Steam profile, click on edit profile, find privacy settings, adjust inventory options to public, and uncheck the option box below.</div>
<!--          <div class="img"><img src="../assets/img/faq/faq5.png"></div>-->
          <div class="title3">Q：Retrieved it for a long time without sending a quotation</div>
          <div class="title4">A：Some accessories are shipped manually, so the delivery time may be delayed compared to automatic delivery. We will search for matching accessories on the entire network, and they can usually be shipped within 24 hours. Please be patient and try to retrieve them multiple times if delivery fails.</div>
          <div class="title4">PS：Attention: If the quotation is not accepted within one hour after sending, the system will automatically cancel the transaction.</div>

          <div class="name-title">6.Other issues</div>
          <div class="title3">Q：How do I use invitation codes?</div>
          <div class="title4">A：After registration, you can find and fill in the invitation code on the personal information page (third option) in the personal center. The invitation code will provide you with an additional 5% profit when you first recharge. You can also find your distribution link and distribution code on the distribution page and share them with your friends. Their recharge and betting behavior in the game will also bring you profits.</div>
          <div class="title3">Q：What is the price basis for accessories?</div>
          <div class="title4">A：We value each accessory based on the prices in the Steam market, and the accessory valuation is consistent across the entire platform. You can also choose to exchange the blind box reward jewelry directly for your balance.</div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      qq群: "313602570",
      email: "313602570",
      url1: "https://steamcommunity.com/id/me/tradeoffers/privacy#trade_offer_access_url",
      url2: "https://help.steampowered.com/zh-cn/wizard/Login?redir=%2Fzh-cn%2Fwizard%2FHelpWhyCantITrade&title=%E7%99%BB%E5%BD%95"
    }
  },
  methods: {
    //获得steam交易链接
    goUrl(url) {
      window.open(url, "_blank");
    },
  }

};
</script>

<style lang="less" scoped>
.doubt {
  overflow: hidden;
  overflow-y: auto;
  width: 100%;
  height: 100%;
  background-color: #1a1c24;

  .doubt-warp {
    width: 1200px;
    margin: 0 auto;
    padding: 20px 0;
    margin-bottom: 50px;

    ul li {
      .name {
        color: #c3c3e2;
        font-size: 24px;
        font-weight: 600;
      }
      .name-title {
        font-size: 20px;
        margin: 20px 0 0 20px;
        color: #c3c3e2;
      }
      .p {
        font-weight: 400;
        font-size: 16px;
        color: #848492;
        padding: 8px 0;
      }
      .title-p {
        color: #848492;
        margin: 20px 0 0 20px;
      }
    }

    .title1 {
      padding: 20px 0 0 40px;
      font-size: 16px;
      color: #c3c3e2;
      font-weight: 600;
    }
    .title2 {
      padding: 20px 0 0 60px;
      font-size: 16px;
      color: #848492;
      span {
        text-decoration: underline;
        color: #17b4ed;
      }
      span:hover {
        cursor: pointer;
      }
    }
    .title3 {
      padding: 20px 0 0 60px;
      font-size: 14px;
      color: #c3c3e2;
      font-weight: 600;
    }
    .title4 {
      padding: 20px 0 0 60px;
      font-size: 14px;
      color: #848492;
      font-weight: 600;
      span {
        text-decoration: underline;
        color: #17b4ed;
      }
      span:hover {
        cursor: pointer;
      }
    }
    .img {
      padding: 20px 0 0 60px;
    }
  }
}
</style>